<template>
	<svg
		version="1.1"
		xmlns="http://www.w3.org/2000/svg"
		xmlns:xlink="http://www.w3.org/1999/xlink"
		x="0px"
		y="0px"
		viewBox="0 0 288 288"
		enable-background="new 0 0 288 288"
		xml:space="preserve"
	>
		<g>
			<path
				fill="#FFFFFF"
				d="M257.3,41.1c0-11.1-7.9-19.5-18.5-19.5c-8.8,0-15.9,5.7-18.5,14.7l-28.5,93l-27.9-89.4
		c-4.1-13.5-9.4-18.6-20.3-18.6c-9.4,0-15.3,5.4-19.1,18.6l-27.9,89.4l-28.2-93c-2.9-9-10.3-14.7-19.1-14.7
		c-10.6,0-18.5,8.4-18.5,19.5c0,2.7,0.6,5.1,1.5,7.5L73.9,168c4.7,13.2,12,19.5,22.9,19.5c10.3,0,16.5-5.7,20.6-18l26.7-83.4
		l26.7,83.4c3.8,12.3,10.6,18,20.3,18c11.2,0,18.5-6.3,23.2-19.5L256,48.6C257,46.2,257.3,43.8,257.3,41.1z"
			/>
			<path
				fill="#FFFFFF"
				d="M40.7,266.7c4.6,0,5.7-0.8,10.3-3.2c8-4.2,37.9-29.7,93-29.7s84.9,25.4,93,29.7c4.6,2.3,5.7,3.2,10.3,3.2
		c6.9,0,12.6-5.3,12.6-11.7c0-5.3-2.8-7.6-4.6-9.5c-9.2-9.5-45.9-39.2-111.3-39.2S41.9,236,32.7,245.5c-1.8,1.9-4.6,4.2-4.6,9.5
		C28.1,261.4,33.8,266.7,40.7,266.7z"
			/>
		</g>
	</svg>
</template>
